<div class="door-document-card-wrapper">
  <div class="door-document-card-left-box"></div>
  <div class="door-document-card-content">
    <div class="door-document-card-file-name">{{document()?.fileName}}</div>
    @if(document().progress() != 1){
      <ion-progress-bar [value]="document().progress()" class="door-document-card-progress"></ion-progress-bar>
    }
    <div class="door-document-card-info-wrapper">
      @if(this.document()?.shortName){
        <div class="document-card-short-name">{{"doorDocumentCard.component.shortName" | translate}}: {{document()?.shortName}}</div>
      }
      <div class="door-document-card-upload-info-wrapper">
        <div class="door-document-card-upload-info-left">
          <div>{{"doorDocumentCard.component.lastUploadedBy" | translate}}: {{document()?.uploadedUserName}}</div>
          <div class="door-document-card-change-date">
            <img src="/assets/icon/time.png" class="door-document-card-time-icon" alt="Last changed">
            <div>{{document()?.lastUploadedDate| date}}</div>
          </div>
        </div>
        <div class="door-document-card-upload-info-right">
          <ion-icon class="door-document-card-download-icon" name="download-outline" (click)="onDownloadClick()"
            title="{{'doorDocumentCard.component.downloadIconTitle' | translate}}">
          </ion-icon>
          <button class="door-document-card-detach-button" [disabled]="readonly()" (click)="onDetachClick()" 
            title="{{'doorDocumentCard.component.detachIconTitle' | translate}}" >
            <ion-icon class="door-document-card-detach-icon" name="trash-outline"></ion-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>