<ion-header class="mbc-modal-header">
  <ion-toolbar class="mbc-modal-toolbar">
    <ion-title class="mbc-modal-title">
      {{"connectProject.modal.title"|translate}}
      <ion-spinner name="circles" class="connect-project-card-spinner" *ngIf="connecting"></ion-spinner>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close-outline" class="mbc-modal-close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content >  
  <div class="mbc-modal-content-container connect-project-modal-container">
    @if (showSpinner) {
      <div class="connect-project-loading-content">
        <ion-spinner name="circles"></ion-spinner>
      </div>
    }@else {
      <div class="mbc-search">
        <ion-searchbar [debounce]="300" 
          placeholder="{{'globalText.mbcSearch.placeHolder'|translate}}" 
          (ionInput)="handleInput($event)"></ion-searchbar>
      </div>
    
      <div class="mbc-main-tile">
        <div class="mbc-main-tile-header">
          <div class="mbc-main-tile-header-name">
            {{"connectProject.modal.mbeProjectCard.title"|translate}}
          </div>
          <div class="mbc-main-tile-header-info">
            {{'connectProject.modal.mbeProjectCard.headerInfo' | translate : {mbeProjectsLength:filteredProjects.length} }}
          </div>
        </div>
        <div class="mbc-main-tile-content" id="connect-project-card-div">
          <div *ngFor="let project of filteredProjects" [id]="project.mbeProjectId">
            <connect-project-card 
              [project]="project" 
              [selected]="selectedProject && selectedProject.mbeProjectId == project.mbeProjectId"
              (click)="onSelectProject(project)" 
              (connectProjectClick)="onConnectProject(project)">
            </connect-project-card>
          </div>
        </div>
      </div>
      <div class="mbc-modal-button-wrapper">
        <ion-button [disabled]="!selectedProject" class="mbc-button-primary" (click)="onConnect()">
         {{"connectProject.modal.mbeProjectCard.connectBtn"|translate}}
        </ion-button>
      </div>
    }
  </div>    
</ion-content>
