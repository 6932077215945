export enum MBCFunctionality{
    NONE = 0,
    COLORS = 1,
    DOOR_PROPS = 2,
    TAGS = 3,
    SETTINGS = 4,
    PROJECT_SETTINGS = 5,
    FILTER = 6,
    TOOLS = 7,
    USER_SETTINGS = 8,
    DETAILING_TEMPLATE = 9,
    WORKORDER_TEMPLATES = 10
}