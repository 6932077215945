import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'mbc-rename-project',
  templateUrl: './rename-project.component.html',
  styleUrls: ['./rename-project.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, FormsModule],
})
export class RenameProjectComponent  implements OnInit {

  @Input() projectName: string;

  initialProjectName: string;

  constructor(private modelCtrl: ModalController) { }

  ngOnInit() {
    this.initialProjectName = this.projectName;
  }

  onSubmit(){
    this.modelCtrl.dismiss(this.projectName);
  }
  
  onCancel() {
    this.modelCtrl.dismiss();
  }
}
