import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ProjectContext } from './datacontext.service';
import { MBCProject, MBEDoor, MBEProject, MBEProjectDoorStructure, MBEProjectUserRole, MBEUser, 
  MBPProject } from 'mbc-models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private httpService: HttpService) {
  }

  public getConnectedProjects(): Observable<MBCProject[]> {
    return this.httpService.getObject<MBCProject[]>("Projects");
  }

  public deleteProject(projectId:string) : Observable<any>{
    return this.httpService.deleteObject<any>("Projects/" + projectId);
  }

  public renameProject(projectId: string, newName: string): Observable<any> {
    return this.httpService.patchObjects<any>(`Projects/${projectId}/rename`, {name: newName});
  }
  
  public getNotConnectedProjects(): Observable<MBEProject[]> {
    return this.httpService.getObject<MBEProject[]>("Projects/unconnected/");
  }

  public connectProject(mbeProjectId: string): Observable<MBEProject> {
    let body = {mbeProjectId:mbeProjectId}
    return this.httpService.sendObjects<any>(`Projects/connect`,body);
  }
  
  public getMBEProjectUserRole(mbeProjectId:string):Observable<MBEProjectUserRole>{
    return this.httpService.getObject<MBEProjectUserRole>(`mbeProjects/${mbeProjectId}/userRole`);
  }

  public getConnectedProject(projectId:string): Observable<MBCProject> {
    return this.httpService.getObject<MBCProject>(`Projects/${projectId}`);
  }

  public getMBEDoorsCount(mbeProjectId:string) : Observable<number>{
    return this.httpService.getObject<number>(`mbeProjects/${mbeProjectId}/doors/count`);
  }

  public getMBEUsers(mbeProjectId:string) : Observable<MBEUser[]>{
    return this.httpService.getObject<MBEUser[]>(`mbeProjects/${mbeProjectId}/users`);
  }

  public getMBEProjectDoorStructure(mbeProjectId: string): Observable<MBEProjectDoorStructure[]>{
    return this.httpService.getObject<MBEProjectDoorStructure[]>(`mbeProjects/${mbeProjectId}/doorStructure`);
  }

  public connectMBPProject(projectId: string, mbpProjectId:string): Observable<MBCProject> {
    let body = {mbpProjectId:mbpProjectId}
    return this.httpService.sendObjects<any>(`Projects/${projectId}/connect/progress`,body);
  }

  public getMBPProjectDetails(mbeProjectId:string) : Observable<MBPProject>{
    return this.httpService.getObject<MBPProject>(`mbpProjects/mbe/${mbeProjectId}`);
  }

  public getPermissions(projectId: string): Observable<ProjectContext>{
    return this.httpService.getObject<ProjectContext>(`Projects/${projectId}/permissions`);
  }

  public connectMBMProject(projectId: string): Observable<MBCProject> {
    return this.httpService.sendObjects(`Projects/${projectId}/connect/management`,{});
  }
}
