export enum FilterType{
    NONE = 0,
    STATUSES = 1,
    ROOM_TYPE = 2,
    MBC_COLOR = 3,
    COLOR = 4,
    FLOOR = 5,
    CONNECTED = 6,
    UNCONNECTED = 7,
    ALL = 8,
    IN_SERVICE_ROUND = 9
}