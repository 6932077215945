import { inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, switchMap } from 'rxjs';
import { BlobUtil, CanvasUtil, DocumentDownloaderService } from 'mbc-utils';
import { ErrorMessageService, NotificationService } from 'mbc-services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class GeneratePDFService {

    private notifications = inject(NotificationService);
    private errorMessage = inject(ErrorMessageService);

    private documentDownloader = inject(DocumentDownloaderService);
    private canvasUtil = inject(CanvasUtil);
    
    private translate = inject(TranslateService);

    constructor(private httpService: HttpService, private blobUtil: BlobUtil) { }

    public fromSvg(svgData: string): Observable<string> {
        return this.httpService.downloadFileByPost(`generatepdf/fromsvg`,{ svgData: svgData })
          .pipe(switchMap(data => this.blobUtil.readBlobAsDataURL(data)));
    }

    printPDF(canvas: any, fileName: string, onComplete:() => void){
        this.notifications.showWarningNotification(
          this.translate.instant('mbcViewer.generatePDF.notification', {fileName: fileName}));
        
        this.canvasUtil.getFullSvgFromCanvas(canvas, (svgData) => {
          this.fromSvg(svgData).subscribe({
            next: fileURL => {
                this.documentDownloader.saveDocument(fileURL, fileName + '.pdf');
            },
            error: error => {
              this.errorMessage.displayErrorMessage(
                this.translate.instant('mbcViewer.generatePDF.failed.header'),
                this.translate.instant('mbcViewer.generatePDF.failed.message', {fileName: fileName}),
              );
              error.handled = true;
            }
          }).add(() => onComplete());
        });
      }
}
