import { MBPCompanyTrade } from "mbc-models";
import { NodeInfo } from "./node-info.model";

export class MBPDoor {
  Id:string;
  DoorId:string;
  Status:string;
  ProjectId:string;
  MbeDoorId:string;
  DoorQty:string;
  DoorNo:string;
  Building:string;
  Comment:string;
  Revision:string;
  RoomNo:string;
  RoomType:MBPRoomType;
  Func:string;
  LR:string;
  Floor:string;
  Fly:string;
  Panel:string;

  T1:MBPCompanyTrade;
  T2:MBPCompanyTrade;
  T3:MBPCompanyTrade;
  T4:MBPCompanyTrade;
  T5:MBPCompanyTrade;
  T6:MBPCompanyTrade;
  T7:MBPCompanyTrade;
  T8:MBPCompanyTrade;
  T9:MBPCompanyTrade;
  T10:MBPCompanyTrade;
  T11:MBPCompanyTrade;
  T12:MBPCompanyTrade;
  T13:MBPCompanyTrade;
  T14:MBPCompanyTrade;
  T15:MBPCompanyTrade;

  D2:MBPDoorField;
  D3:string;
  D4:string;
  D5:MBPDoorField;
  D6:MBPDoorField;
  D7:MBPDoorField;
  D8:MBPDoorField;
  D9:MBPDoorField;
  D10:string;
  D11:string;
  D11Qty:number;
  D20:string;
  D21:string;
  D22:string;
  D23:string;
  D24:string;
  D25:string;
  D26:string;
  D27:string;
  D28:string;
  DC1:string;
  DC2:string;
  DC3:string;
  DC4:string;

  HB:string;

  H1:MBPHardware;
  H2:MBPHardware;
  H3:MBPHardware;
  H5:MBPHardware;
  H6:MBPHardware;
  H7:MBPHardware;
  H8:MBPHardware;
  H9:MBPHardware;

  H10:MBPHardware;
  H11:MBPHardware;
  H12:MBPHardware;
  H13:MBPHardware;
  H14:MBPHardware;
  H15:MBPHardware;
  H16:MBPHardware;
  H17:MBPHardware;
  H19:MBPHardware;

  H22:MBPHardware;
  H23:MBPHardware;
  H24:MBPHardware;
  H25:MBPHardware;
  H26:string;
  H27:MBPHardware;
  H28:MBPHardware;
  H29:MBPHardware;

  H30:MBPHardware;
  H31:MBPHardware;
  H32:MBPHardware;
  H33:MBPHardware;
  H35:MBPHardware;

  H40:MBPHardware;
  H41:MBPHardware;
  H42:MBPHardware;
  H43:MBPHardware;
  H44:MBPHardware;
  H45:MBPHardware;
  H46:MBPHardware;
  H47:MBPHardware;
  H48:MBPHardware;
  H49:MBPHardware

  ExternalId1:string;
  ExternalId2:string;

  Secure:string;
  DoorBundle:string;
  ChangedDate?:Date
  ChangedBy:string; 

  URF1:string;
  URF2:string;
  URF3:string;
  URF4:string;
  URF5:string;
  URF6:string;
  URF7:string;
  URF8:string;

  mbpCurrentTradeInfo: MBPCompanyTradeInfo;
  connected:boolean;
  nodeInfo:NodeInfo;
  doorTags: string[];
}

export class MBPRoomType {
  Content?: string;
}

export class MBPDoorField{
  Content:string;
  CompanyId:string;
  Mounted:boolean;
}

export class MBPHardware{
  Id:string;
  Qty:number;
  Content:string;
  Surf:string;
  CompanyId:string;
  Mounted:boolean;
  ChecklistId:string;
}

export class MBPCompanyTradeInfo{
  Status:string;
  color:string;
  foreColor:string;
  Description?:string;
}