import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MBCProject } from 'mbc-models';
import { DatacontextService } from 'mbc-services';

@Component({
  selector: 'mbc-project-card',
  templateUrl: './mbc-project-card.component.html',
  styleUrls: ['./mbc-project-card.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class MbcProjectCardComponent {
  isOpen = false;
  @ViewChild('popover') popover;

  @Input() mbcProject: MBCProject;
  @Input() selected: boolean;

  @Output() nextClick: EventEmitter<Event> = new EventEmitter();
  @Output() deleteClick: EventEmitter<Event> = new EventEmitter();
  @Output() renameClick: EventEmitter<Event> = new EventEmitter();

  constructor(public dataContextService: DatacontextService) { }

  onNextClick() {
    this.nextClick.emit();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  onDeleteMenuClick() {
    if (!this.mbcProject.userAccess.isMBEAdmin) {
      return false;
    }
    this.deleteClick.emit();
    this.isOpen = false;
  }

  onRenameMenuClick() {
    this.renameClick.emit();
    this.isOpen = false;
  }
}
