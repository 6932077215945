import { MBCProjectSetting, MBEProjectUserRole } from "mbc-models";

export class MBCProject {
    id: string;
    mbeProjectId:string;
    mbpProjectId:string;
    mbmProjectId:string;
    name: string;
    connectedUserId: string;
    createdDate: string;
    connectedUserName:string;

    projectSetting: MBCProjectSetting;
    userAccess: UserAccess;
}

export class UserAccess{
    isMBEAdmin: boolean;
    isMBPAdmin: boolean;
    isMBMAdmin: boolean;
}