  <div class="floor-plan-pdf-card-wrapper">
    <ion-reorder>
      <div class="floor-plan-pdf-card-left-box">
        <div slot="end" class="floor-plan-pdf-card-left-box-inner">
          <img src="/assets/icon/three-dots.png" class="floor-plan-pdf-card-left-box-icon" 
            alt="Pdf file left box">
        </div>
      </div>
    </ion-reorder>
    <div class="floor-plan-pdf-card-wrapper-inner">
    <div class="floor-plan-card-content-holder">
      <div class="floor-plan-pdf-card-header">    
        <img src="/assets/icon/blue-check-icon.png" class="floor-plan-pdf-card-icon" alt="Upload progress">
        <div class="floor-plan-pdf-card-name">
          {{floorPlanFile.pdfFileName}} 
        </div>
      </div>
      <ion-progress-bar [value]="floorPlanFile.progress" class="floor-plan-pdf-card-progress"></ion-progress-bar>
      <div class="floor-plan-pdf-card-info">
        <div class="floor-plan-pdf-card-info-left">
          <div class="floor-plan-pdf-card-floor-number" *ngIf="this.floorPlanFile.shortName != '' 
                    &&  this.floorPlanFile.shortName != undefined ">
                    {{"floorPlanFiles.updateShortname.popup.input" | translate}}: {{floorPlanFile.shortName}}</div>
          <div>{{"floorPlanPdf.component.lastUploadedBy" | translate}}: {{floorPlanFile.uploadedUserName}}</div>
        </div>
        <div class="floor-plan-pdf-card-info-right">
          <img src="/assets/icon/time.png" class="floor-plan-pdf-card-change-icon" alt="Last changed">
          <div>{{floorPlanFile.lastUploadedDate | date}}</div>
        </div>
      </div>
    </div>
    <img *ngIf="this.floorPlanFile.id != ''" src="/assets/icon/dots.png" class="floor-plan-pdf-card-menu-icon" 
      (click)="presentPopover($event)" alt="Pdf file more options">
    </div>
  </div>
  
  <ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
    <ng-template>
      <div class="mbc-card-menu">
        <div class="mbc-card-menu-item-default" 
          [class.disabled]="readOnly" 
          (click)="!readOnly && onReplaceFileSelected()">{{"floorPlanPdf.component.menu.replace" | translate}}</div>
        <div class="mbc-card-menu-item-default" 
          [class.disabled]="readOnly" 
          (click)="!readOnly && onUpdateShortname()">{{"floorPlanPdf.component.menu.updateShortname" | translate}}</div>
        <div class="mbc-card-menu-item-important" 
          [class.disabled]="readOnly" 
          (click)="!readOnly && onRemoveFile()">{{"floorPlanPdf.component.menu.removeFile" | translate}}</div>
      </div>
    </ng-template>
  </ion-popover>