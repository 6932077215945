import { ErrorHandler, Injectable, Injector} from "@angular/core";
import { ErrorMessageService } from "mbc-services";

@Injectable()
export class MBCGlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector){}

    handleError(error: any) {
        const errorMessageService = this.injector.get(ErrorMessageService);
        const isOriginalErrorUnhandled = !error.originalError || !error.originalError.handled;
        const isErrorUnhandled = !error.handled;

        if (isOriginalErrorUnhandled && isErrorUnhandled) {
            errorMessageService.displayGlobalErrorMessage();
            console.log(error);            
        } else {
            console.log("error handled");
        }  
    }
  }