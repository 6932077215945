export class MBMMessage {
  id: string;
  projectId: string;
  workorderId: string;
  doorId: string;
  doorNo: string;
  subject: string;
  body: string;
  picture: string;
  sender: MBMMessageUser;
}

export class MBMMessageUser {
  senderName: string;
  companyId: string;
  mbeUserId: string;
  timestamp: Date;
}