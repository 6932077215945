import { Routes } from '@angular/router';
import { AuthGuard, isProjectMBEUser, isProjectMBEEditUser, isProjectUser, canDeactivate, isProjectMBPUserOrMBEUser } from 'mbc-guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main-dashboard',
    pathMatch: 'full',
  },
  {
    path: 'projects/:projectId/floor-plan-viewer',
    loadComponent: () =>
      import('./pages/floor-plan-viewer/floor-plan-viewer.page').then((m) => m.FloorPlanViewerPage),
    canActivate: [isProjectMBEUser],
    canDeactivate: [canDeactivate],
  },
  {
    path: 'main-dashboard',
    loadComponent: () =>
      import('./pages/main-dashboard/main-dashboard.page').then(
        (m) => m.MainDashboardPage
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'projects',
    redirectTo: 'main-dashboard'
  },
  {
    path: 'auth-callback',
    loadComponent: () =>
      import('./pages/auth-callback/auth-callback.page').then(
        (m) => m.AuthCallbackPage
      ),
  },
  {
    path: 'projects/:projectId/dashboard',
    loadComponent: () =>
      import('./pages/project-dashboard/project-dashboard.page').then(
        (m) => m.ProjectDashboardPage
      ),
    canActivate: [isProjectUser],
  },
  {
    path: 'projects/:projectId',
    redirectTo: 'projects/:projectId/dashboard'
  },
  {
    path: 'projects/:projectId/floor-plan-files',
    loadComponent: () => import('./pages/floor-plan-files/floor-plan-files.page')
      .then( m => m.FloorPlanFilesPage),
    canActivate: [isProjectMBEEditUser],
  },   
  {
    path: 'projects/:projectId/progress-floor-plan-viewer/:mbpCompanyId',
    loadComponent: () => import('./pages/progress-floor-plan-viewer/progress-floor-plan-viewer.page')
        .then( m => m.ProgressFloorPlanViewerPage),
    canActivate: [isProjectMBPUserOrMBEUser]
  },
  {
    path: 'projects/:projectId/management-viewer/:workorderId',
    loadComponent: () => import('./pages/management-viewer/management-viewer.page').then( m => m.ManagementViewerPage),
    canActivate: [isProjectUser],
  },
  {
    path: 'static-mobile-friendly',
    loadComponent: () => import('./pages/static-mobile-friendly/static-mobile-friendly.page').then( m => m.StaticMobileFriendlyPage)
  },
  {
    path: 'projects/:projectId/documents',
    loadComponent: () => import('./pages/documents/documents.page').then( m => m.DocumentsPage),
    canActivate: [isProjectUser],
  },

];