import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MBMProjectSetting, TagProperty } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MBMProjectSettingService {

  constructor(private httpService: HttpService) { }

  public getMBMProjectSetting(projectId: string): Observable<MBMProjectSetting> {
    return this.httpService.getObject<MBMProjectSetting>(`project/${projectId}/mbmSettings/`);
  }

  public updateMBMProjectTagSetting(projectId: string, mbmProjectTags: TagProperty[]): Observable<TagProperty[]> {
    return this.httpService.patchObjects<TagProperty[]>(`project/${projectId}/mbmSettings/tags`, mbmProjectTags);
  }

  public updateDoorNodeTemplate(projectId:string, doorNodeTemplate: number) : Observable<void>{
    let body = {doorNodeTemplate:doorNodeTemplate};
    return this.httpService.patchObjects<any>(`project/${projectId}/mbmSettings/doorNodeTemplate`,body);
  }

  public updateDoorNodeSizeZoom(projectId:string, doorNodeSizeZoom: number) : Observable<void>{
    let body = { doorNodeSizeZoom: doorNodeSizeZoom };
    return this.httpService.patchObjects<any>(`project/${projectId}/mbmSettings/doorNodeSizeZoom`,body);
  }
}
