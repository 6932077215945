import { MBMDoorStatus } from "mbc-enums";
import { MBMDoor } from "./mbm-door.model";

export class MBMWorkorderDoor {
    public Id: string;
    public ProjectId: string;
    public WorkeorderId: string;
    public FinishedDate: Date;
    public Door: MBMDoor;
    public DoorStatus: MBMDoorStatus;
}