import { Signal, WritableSignal, computed, signal } from "@angular/core";
import { MBCColor } from "./mbc-color.model";
import { NodeInfo } from "./node-info.model";

export class MBEDoor {
  Id?: string;
  ProjectId?: string;
  MbeProductionId?: string;
  DoorQty?: number;
  DoorNo?: string;
  Building?: string;
  Comment?: string;
  Revision?: string;
  RoomNo?: string;
  RoomType?: MBERoomType;
  Func?: string;
  LR?: string;

  D2?: MBEDoorField;
  D3?: string;
  D4?: string;
  D5?: MBEDoorField;
  D6?: MBEDoorField;
  D7?: MBEDoorField;
  D8?: MBEDoorField;
  D9?: MBEDoorField;
  D10?: string;
  D11?: string;
  D11Qty?: number;

  D20?: string;
  D21?: string;
  D22?: string;
  D23?: string;
  D24?: string;
  D25?: string;
  D26?: string;
  D27?: string;
  D28?: string;

  DC1?: string;
  DC2?: string;
  DC3?: string;
  DC4?: string;

  Floor?: string;
  Fly?: string;
  HB?: string;

  H1?: MBEHardware;
  H10?: MBEHardware;
  H11?: MBEHardware;
  H12?: MBEHardware;
  H13?: MBEHardware;
  H14?: MBEHardware;
  H15?: MBEHardware;
  H16?: MBEHardware;
  H17?: MBEHardware;
  H19?: MBEHardware;

  H2?: MBEHardware;
  H22?: MBEHardware;
  H23?: MBEHardware;
  H24?: MBEHardware;
  H25?: MBEHardware;
  H26?: string;
  H27?: MBEHardware;
  H28?: MBEHardware;
  H29?: MBEHardware;

  H3?: MBEHardware;
  H30?: MBEHardware;
  H31?: MBEHardware;
  H32?: MBEHardware;
  H33?: MBEHardware;
  H35?: MBEHardware;

  H5?: MBEHardware;
  H6?: MBEHardware;
  H7?: MBEHardware;
  H8?: MBEHardware;
  H9?: MBEHardware;

  H40?: MBEHardware;
  H41?: MBEHardware;
  H42?: MBEHardware;
  H43?: MBEHardware;
  H44?: MBEHardware;
  H45?: MBEHardware;
  H46?: MBEHardware;
  H47?: MBEHardware;
  H48?: MBEHardware;
  H49?: MBEHardware;

  HC?: string;

  DoorBundle?: string;
  ChangedBy?: string;

  Panel?: string;
  Secure?: string;

  URF1?: string;
  URF2?: string;
  URF3?: string;
  URF4?: string;
  URF5?: string;
  URF6?: string;
  URF7?: string;
  URF8?: string;

  Revit?: MBERevit;

  DoorId?: string;
  Status?: string;

  connected: Signal<boolean> = signal(false);
  nodeInfo: WritableSignal<NodeInfo> = signal(undefined);
  color: Signal<MBCColor> = signal(undefined);
  foreColor: Signal<MBCColor> = signal(undefined);
  tags: Signal<string[]> = signal([]);

  readOnly: boolean = false;
}

export class MBEDoorField {
  Content?: string;
}

export class MBEHardware {
  Qty?: number;
  Content?: string;
  Surf?: string;
}

export class MBERevit {
  RevitId?: string;
  Format?: string;
  WallThickness?: string;
  Fireres?: string;
  SoundProof?: string;
  R1?: string;
  R2?: string;
  R3?: string;
  R4?: string;
  R5?: string;
  R6?: string;
  R7?: string;
}

export class MBERoomType {
  Content?: string;
}