import { Injectable } from "@angular/core";
import { CanvasSettings } from "mbc-settings";

@Injectable({
    providedIn: 'root',
})
export class CanvasUtil {

    getFullSvgFromCanvas(canvas: any, afterGetSvg: (svgData: string) => void) {
        canvas.clone((clonedCanvas) => {
            let svgData: string = this.getSvgFromCanvas(clonedCanvas);
            afterGetSvg(svgData);
        });
    }

    private getSvgFromCanvas(canvas: any): string{
        canvas.width = canvas.backgroundImage?.width ?? canvas.width;
        canvas.height = canvas.backgroundImage?.height ?? canvas.height;
        canvas.setZoom(1);
        canvas.viewportTransform[4] = 0;
        canvas.viewportTransform[5] = 0;
        return canvas.toSVG();
    }

    checkZoomLimit(zoom: number): number {
        if (zoom < CanvasSettings.maxZoomOut) { return CanvasSettings.maxZoomOut; }
        if (zoom > CanvasSettings.maxZoomIn) { return CanvasSettings.maxZoomIn; }
        return zoom;
    }
}