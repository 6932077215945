import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DocumentDownloaderService {

  constructor() { }

  saveDocument(fileURL: string, fileName: string) {    
    saveAs(fileURL, fileName);
  }
}
