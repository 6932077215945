<ion-header class="mbc-modal-header">
  <ion-toolbar class="mbc-modal-toolbar">
    <ion-title class="mbc-modal-title">{{"renameProject.modal.header" | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="mbc-modal-content-container rename-project-input-container">
    <div class="mbc-main-tile">
      <div class="mbc-rename-input">
        <form #projectForm="ngForm">
          <ion-input class="mbc-input" [(ngModel)]="projectName" name="projectName" 
            placeholder='{{"renameProject.modal.input.placeHolder" | translate}}' 
            errorText='{{"renameProject.modal.input.validationError" | translate}}'  
            required minlength="3" maxlength="120">
          </ion-input>
        </form>
      </div>
    </div>
    <div class="mbc-modal-button-wrapper">
      <ion-button class="mbc-button-primary" (click)="onCancel()">
        {{ "renameProject.modal.cancel" | translate}}
      </ion-button>
      <ion-button class="mbc-button-primary" (click)="onSubmit()" [disabled]="projectForm.invalid || projectName === initialProjectName">
        {{ "renameProject.modal.update" | translate}}
      </ion-button>
    </div>
   </div>
</ion-content>