export class ProjectDetailingTemplate{
    projectId: string;
    items: DetailingCode[];
}

export class DetailingCode{
    code: string;
    description?: string;
    color?: string;
    foreColor?: string;
}