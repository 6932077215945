import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { MBPProjectSetting, TagProperty } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class MbpProjectSettingService {

  constructor(private httpService: HttpService) { }

  public getMBPProjectSetting(projectId: string): Observable<MBPProjectSetting> {
    return this.httpService.getObject<MBPProjectSetting>(`project/${projectId}/mbpSettings/`);
  }

  public updateMBPProjectTagSetting(projectId: string, mbpProjectTags: TagProperty[]): Observable<TagProperty[]> {
    return this.httpService.patchObjects<TagProperty[]>(`project/${projectId}/mbpSettings/tags`, mbpProjectTags);
  }

  public updateDoorNodeTemplate(projectId:string, doorNodeTemplate: number) : Observable<void>{
    let body = {doorNodeTemplate:doorNodeTemplate};
    return this.httpService.patchObjects<any>(`project/${projectId}/mbpSettings/doorNodeTemplate`,body);
  }

  public updateDoorNodeSizeZoom(projectId:string, doorNodeSizeZoom: number) : Observable<void>{
    let body = { doorNodeSizeZoom: doorNodeSizeZoom };
    return this.httpService.patchObjects<any>(`project/${projectId}/mbpSettings/doorNodeSizeZoom`,body);
  }
}