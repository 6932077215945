import { MBMDoorStatus } from "mbc-enums";

export class MBMHardwareMaintenanceDetail {
  fieldName: string;
  header: string;
  content: string;
  isMaintainable: boolean;
  isMaintained: boolean;
  checklistId: string;
  qty: string;
  doorStatus: MBMDoorStatus;
}