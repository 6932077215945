import { MBEDoor, MBMDoor, MBPDoor, TagProperty } from "mbc-models";

export class FieldNameUtil {

  extractValue_MBEDoor(door: MBEDoor, fieldName: string) {
    return fieldName.split('.').reduce((o, key) => {
      if(o){ return o[key];}
      else return null;
    }, door);
  }
  
  extractValue_MBPDoor(door: MBPDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      if(o){ return o[key];}
      else return null;
    }, door);
  }

  extractValue_MBMDoor(door: MBMDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      if(o){ return o[key];}
      else return null;
    }, door);
  }

  extractObjectPropertyValueForViewer(door: MBPDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      if(key.includes('Qty')){
        if(o?.[key] && o?.[key] <= 1){
          return null;
        }
      }
      return o?.[key];
    }, door);
  }

  extractObjectPropertyValueForMBMViewer(door: MBMDoor, fieldName: string): string {
    return fieldName.split('.').reduce((o, key) => {
      if (key.includes('Content') && o?.['Qty'] > 1) {
        return o?.[key] + ' x' + o?.['Qty'];
      }
      if (key.includes('Qty')) {
        return null;
      }
      return o?.[key];
    }, door);
  }

  getOrderNumberForField(fieldName: string, tagProperties: TagProperty[]): number {
    return (
      tagProperties.find(
        (tag) => tag.field === fieldName
      )?.orderNo || 0
    );
  }

  extractFieldName(fieldName: string): string[]{
    return fieldName.split('.').map(c => (c.charAt(0) + c.slice(1)))
  }

  getFieldNameInClientFormat(fieldName: string): string {
    let namesArray = this.extractFieldName(fieldName);
    return namesArray.join('.');
  }

  setValueForField(object: object, fieldName: string, value: any){
    let namesArray = fieldName.split('.');
    let obj = object;    
    for(let i = 0; i < namesArray.length - 1; i++){
      if(!obj[namesArray[i]]){
        obj[namesArray[i]] = {};
      }
      obj = obj[namesArray[i]];      
      if(i === namesArray.length - 2){
        obj[namesArray[i+1]] = value;
      }
    }
  }
}
