import { DoorImage } from "./door-image.model";

export class DoorNote {
    id: string;
    projectId: string;
    mbeDoorId: string;
    text: string;
    images: DoorImage[];
}

