import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DetailingCode, ProjectDetailingTemplate } from 'mbc-models';

@Injectable({
  providedIn: 'root'
})
export class DetailingService {

  private httpService: HttpService = inject(HttpService);

  constructor() { }

  getProjectDetailingTemplate(projectId: string): Observable<ProjectDetailingTemplate>{
    return this.httpService.getObject<ProjectDetailingTemplate>(`projects/${projectId}/detailingTemplate`);
  }

  getProjectDetailingCodes(projectId: string): Observable<DetailingCode[]>{
    return this.httpService.getObject<DetailingCode[]>(`projects/${projectId}/detailingTemplate/codes`);
  }

  updateProjectDetailingCodes(projectId: string, detailingCodes: DetailingCode[]): Observable<ProjectDetailingTemplate>{
    return this.httpService.patchAndGetObjects<ProjectDetailingTemplate, DetailingCode[]>(`projects/${projectId}/detailingTemplate/codes`, detailingCodes);
  }
}