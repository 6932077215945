import { signal, Signal, WritableSignal } from "@angular/core";
import { MBMDoorStatus } from "mbc-enums";
import { NodeInfo } from "mbc-models";

export class MBMDoor
{
    Id?: string;    
    MbeDoorId: string;
    ProjectId?: string;
    MbeProductionId?: string;
    DoorQty?: number;
    DoorNo?: string;
    Building?: string;
    Comment?: string;
    Revision?: string;
    RoomNo?: string;
    RoomType?: MBMRoomType;
    Func?: string;
    LR?: string;
  
    D2?: MBMDoorField;
    D3?: string;
    D4?: string;
    D5?: MBMDoorField;
    D6?: MBMDoorField;
    D7?: MBMDoorField;
    D8?: MBMDoorField;
    D9?: MBMDoorField;
    D10?: string;
    D11?: string;
    D11Qty?: number;
  
    D20?: string;
    D21?: string;
    D22?: string;
    D23?: string;
    D24?: string;
    D25?: string;
    D26?: string;
    D27?: string;
    D28?: string;
  
    DC1?: string;
    DC2?: string;
    DC3?: string;
    DC4?: string;
  
    Floor?: string;
    Fly?: string;
    HB?: string;
  
    H1?: MBMHardware;
    H10?: MBMHardware;
    H11?: MBMHardware;
    H12?: MBMHardware;
    H13?: MBMHardware;
    H14?: MBMHardware;
    H15?: MBMHardware;
    H16?: MBMHardware;
    H17?: MBMHardware;
    H19?: MBMHardware;
  
    H2?: MBMHardware;
    H22?: MBMHardware;
    H23?: MBMHardware;
    H24?: MBMHardware;
    H25?: MBMHardware;
    H26?: string;
    H27?: MBMHardware;
    H28?: MBMHardware;
    H29?: MBMHardware;
  
    H3?: MBMHardware;
    H30?: MBMHardware;
    H31?: MBMHardware;
    H32?: MBMHardware;
    H33?: MBMHardware;
    H35?: MBMHardware;
  
    H5?: MBMHardware;
    H6?: MBMHardware;
    H7?: MBMHardware;
    H8?: MBMHardware;
    H9?: MBMHardware;
  
    H40?: MBMHardware;
    H41?: MBMHardware;
    H42?: MBMHardware;
    H43?: MBMHardware;
    H44?: MBMHardware;
    H45?: MBMHardware;
    H46?: MBMHardware;
    H47?: MBMHardware;
    H48?: MBMHardware;
    H49?: MBMHardware;
  
    HC?: string;
  
    DoorBundle?: string;
    ChangedBy?: string;
  
    Panel?: string;
    Secure?: string;
  
    URF1?: string;
    URF2?: string;
    URF3?: string;
    URF4?: string;
    URF5?: string;
    URF6?: string;
    URF7?: string;
    URF8?: string;
  
    DoorId?: string;
    Status?: string;

    WorkorderDoorId: string;
    isWorkorderDoor: boolean;
    color: Signal<string> = signal('');
    foreColor: Signal<string> = signal('')
    doorStatus: WritableSignal<MBMDoorStatus> = signal(undefined);
    nodeInfo: WritableSignal<NodeInfo> = signal(undefined);
    doorTags: Signal<string[]> = signal(undefined);
}

export class MBMRoomType
{
    Content: string;
}

export class MBMHardware
{
    Id: string;
    Qty?: number;
    Content: string;
    Surf: string;
    IsMaintained: WritableSignal<boolean> = signal(undefined);
    ChecklistId: string;
    IsMaintainable: boolean;
}

export class MBMDoorField
{
    Content: string;
    CompanyId: string;
    Mounted: boolean;

}