import { Injectable } from "@angular/core";
import { ResizedImage } from "mbc-models";
import { MBCSettings } from 'mbc-settings';

@Injectable({
    providedIn: 'root',
})
export class ImageUtil {

    resizeBase64Image(base64Image: string) : Promise<string>{
        return new Promise((resolve) => {
            const image = new Image();
            image.src = `data:image/jpeg;base64,${base64Image}`;
            image.onload = () => { 
                let maxWidth: number = MBCSettings.imageResizeMaxWidth;
                let maxHeight: number = MBCSettings.imageResizeMaxHeight;
                const resizedBase64Image: string = this.resizeImageToBase64(image, maxWidth, maxHeight, 'image/jpeg');
                resolve(resizedBase64Image);
            }
        });
    }
    
    private resizeImageToBase64(image: HTMLImageElement, maxWidth: number, maxHeight: number, contentType: string): string {
        let canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        if (context) {
            const {width, height} = this.calculateResizeRatio(image.width, image.height, maxWidth, maxHeight);
            
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);
            const imageURL: string = canvas.toDataURL(contentType);
            return this.convertImageURLToBase64(imageURL);;
        }
        return '';
    }

    private calculateResizeRatio(
        width: number, height: number, maxWidth: number, maxHeight: number): { width: number, height: number } {
            if(width > maxWidth && maxWidth !== 0){
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }

            if(height > maxHeight && maxHeight !== 0){
                height = maxHeight;
                width = Math.round((width * maxHeight) / height);
            }
            return { width, height };
    }

    private convertImageURLToBase64(imageURL: string): string{
        const imagePattern = new RegExp(`^data:image\/(${MBCSettings.cameraSupportedFileTypes});base64,`);
        return imageURL.replace(imagePattern, '');
    }

    calculateBase64Size(base64String: string): number {
        let padding = 0;
        if (base64String.endsWith('==')) padding = 2;
        else if (base64String.endsWith('=')) padding = 1;

        const base64Length = base64String.length;
        const sizeInBytes = (base64Length * 3) / 4 - padding;
        const sizeInKB = sizeInBytes / 1024; // size in KB
        return sizeInKB;
    }

    resizeImageURL(imageURL: string, width: number) : Promise<ResizedImage>{
        return new Promise((resolve) => {
            const image = new Image();
            image.src = imageURL;
            image.onload = () => { 
                let base64Image: string = imageURL;
                if(image.width != width){
                    const resizedBase64Image: string = this.resizeImageToBase64(image, width, 0, 'image/png');
                    base64Image = 'data:text/xml;base64,' + resizedBase64Image;
                }

                let resizedImage: ResizedImage = { 
                    base64Image: base64Image, 
                    originalWidth: image.width, 
                    originalHeight: image.height 
                };
                resolve(resizedImage);
            }
        });
    }
}