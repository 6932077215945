import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { DatacontextService } from './datacontext.service';
import { DoorNode, DoorNodeChange, FloorPlanQuality,} from 'mbc-models';
import { ChangeType, ViewerMode } from 'mbc-enums';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private datacontext: DatacontextService
  ) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  /**
   * TODO : Can remove
   */
  async saveAuthToken(variable: string) {
    await this.storage.set('mbc-authToken', variable);
  }

  async getToken(): Promise<any> {
    try {
      return await this.storage.get('mbc-authToken');
    } catch (ex) {
      this.datacontext.log(ex);
    }
  }

  /**
   * TODO : Can remove
   */
  async removeAuthToken() {
    await this.storage.set('mbc-authToken', undefined);
  }

  async saveLastEmail(email: string) {
    await this.storage.set('mbc-last-email', email);
  }

  async getLastEmail(): Promise<string> {
    return this.storage.get('mbc-last-email');
  }

  async getDoorNodeChanges(): Promise<DoorNodeChange[]> {
    return await this.storage.get('door-node-changes');
  }

  async setDoorNodeChanges(doorNodeChanges: DoorNodeChange[]) {
    await this.storage.set('door-node-changes', doorNodeChanges);
  }

  async saveDoorNodes(doorNodes: DoorNode[]) {
    this.datacontext.changedDoorNodeCount = doorNodes.filter(
      (a) =>
        a.doorNodeEvent == ChangeType.ADD ||
        a.doorNodeEvent == ChangeType.UPDATE ||
        a.doorNodeEvent == ChangeType.DELETE
    ).length;
    await this.storage.set('door-nodes', doorNodes);
  }

  async getDoorNodes(): Promise<DoorNode[]> {
    try {
      return await this.storage.get('door-nodes');
    } catch (ex) {
      this.datacontext.log(ex);
    }
  }

  async removeDoorNodes() {
    await this.storage.set('door-nodes', []);
  }

  async setInitialZoom(zoom:number){
    await this.storage.set('initial-zoom', zoom);
  }

  async getInitialZoom() : Promise<number>{
    return await this.storage.get('initial-zoom');
  }

  async removeInitialZoom(){
    await this.storage.set('initial-zoom', undefined);
  }
  
  async setInitialViewerMode(viewerMode: ViewerMode){
    await this.storage.set('initial-viewer-mode', viewerMode);
  }

  async getInitialViewerMode() : Promise<ViewerMode>{
    return await this.storage.get('initial-viewer-mode');
  }

  async setInitialFloorPlanQuality(floorPlanQuality: FloorPlanQuality){
    await this.storage.set('initial-floor-plan-quality', floorPlanQuality);
  }

  async getInitialFloorPlanQuality() : Promise<FloorPlanQuality>{
    return await this.storage.get('initial-floor-plan-quality');
  }
}
